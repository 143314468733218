import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => (
  <footer>
    <div className="footer-content">
      <p>&copy; 2024 Marc Senn. All rights reserved. <Link to="/legal">Legal Notice</Link></p>
      <div className="social-links">
        <a href="https://github.com/marcsenn" target="_blank" rel="noopener noreferrer">GitHub</a>
        <a href="https://linkedin.com/in/marc-senn-06987a183/" target="_blank" rel="noopener noreferrer">LinkedIn</a>
        <Link to="/contact">Contact</Link>
      </div>
    </div>
  </footer>
);

export default Footer;