import React from 'react';
import { Link } from 'react-router-dom';
import TestimonialBox from '../components/TestimonialBox';

const Home = () => (
  <div>
    <h1>Marc Senn</h1>
    <p className="subtitle">Father • Geek • Coder • Photographer</p>

    <div className="testimonial-container">
      <TestimonialBox title="About Me">
        <p>I'm a father, geek, coder, and photographer. I'm passionate about using technology to solve problems and create solutions.</p>
        <p>I studied at ETH Zürich, have over 10 years of experience as a software developer, and currently reside in Schaffhausen, Switzerland.</p>
      </TestimonialBox>
      <TestimonialBox title="Social Media">
        <div className="social-links">
          <a href="https://github.com/marcsenn" target="_blank" rel="noopener noreferrer">GitHub</a>
          <a href="https://linkedin.com/in/marc-senn-06987a183/" target="_blank" rel="noopener noreferrer">LinkedIn</a>
        </div>
      </TestimonialBox>
      <TestimonialBox title="Services">
        <p>Interested in AI consulting? <Link to="/contact">Contact me</Link>.</p>
      </TestimonialBox>
    </div>
    <p><Link to="/website-tech" className="back-link">Learn about this website's technology</Link></p>
  </div>
);

export default Home;