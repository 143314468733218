import React from 'react';
import { Link } from 'react-router-dom';
import ContactForm from '../components/ContactForm';

const Contact = () => {
  return (
    <div>
      <h1>Contact Me</h1>
      <p className="subtitle">Get in touch</p>
      <div className="contact-box">
        <ContactForm />
        <div className="button-container">
          <Link to="/" className="back-link">Back to Home</Link>
        </div>
      </div>
    </div>
  );
};

export default Contact;