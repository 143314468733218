import React from 'react';
import { Link } from 'react-router-dom';

const WebsiteTech = () => (
  <div className="website-tech">
    <h1>Website Technology</h1>
    <p className="subtitle">The tech stack and approach behind this site</p>

    <section className="tech-stack">
      <h2>Tech Stack & Hosting</h2>
      <p>This website is built using modern web technologies:</p>
      <ul>
        <li>React for the frontend framework</li>
        <li>React Router for client-side routing</li>
        <li>CSS for styling</li>
        <li>HTML5 for structure</li>
        <li>Vanilla JavaScript for interactivity (when needed)</li>
        <li>Cloudflare Pages for hosting and deployment</li>
        <li>Cloudflare Workers for serverless backend (contact form)</li>
      </ul>
      <p>The site is statically hosted, ensuring fast load times and minimal maintenance.</p>
    </section>

    <section className="approach">
      <h2>Why This Approach?</h2>
      <p>I chose this tech stack for several reasons:</p>
      <ul>
        <li>Simplicity: Easy to maintain and update</li>
        <li>Performance: Fast loading times</li>
        <li>Accessibility: Works on all devices and browsers</li>
        <li>SEO-friendly: Easy for search engines to crawl and index</li>
        <li>Learning: Explore Cloudflare's ecosystem (Pages, Workers, Wrangler)</li>
        <li>Experimentation: Try out AI-assisted development with Cursor</li>
      </ul>
    </section>

    <section className="purpose">
      <h2>Purpose & Goals</h2>
      <p>This website serves as:</p>
      <ul>
        <li>A professional portfolio showcasing my skills and experience</li>
        <li>A platform to connect with potential clients or employers</li>
        <li>A demonstration of my approach to web development</li>
        <li>A playground for experimenting with CSS styles and layouts</li>
        <li>A project to explore AI-assisted coding using Cursor (cursor.com)</li>
      </ul>
      <p>Interested in discussing web technologies, AI, or my development process? <Link to="/contact" className="cta-link">Get in touch</Link>.</p>
    </section>
    
    <Link to="/" className="back-link">Back to Home</Link>
  </div>
);

export default WebsiteTech;