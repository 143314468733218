import React from 'react';
import { Link } from 'react-router-dom';

const Legal = () => (
  <div>
    <h1>Legal Notice</h1>
    <p className="subtitle">Important Information</p>
    
    <div className="legal-container">
      <div className="legal-box">
        <h2>Contact Information</h2>
        <p>Marc Senn</p>
        <p>Schaffhausen, Switzerland</p>
        <p>Email: Write a mail to hi @ this domain or use the <Link to="/contact">contact form</Link> on the website.</p>
      </div>
      <div className="legal-box">
        <h2>Disclaimer</h2>
        <p>The information provided on this website is for general informational purposes only. All information on the site is provided in good faith, however, we make no representation or warranty of any kind, express or implied, regarding the accuracy, adequacy, validity, reliability, availability, or completeness of any information on the site.</p>
      </div>
      <div className="legal-box">
        <h2>External Links Disclaimer</h2>
        <p>The site may contain (or you may be sent through the site) links to other websites or content belonging to or originating from third parties or links to websites and features in banners or other advertising. Such external links are not investigated, monitored, or checked for accuracy, adequacy, validity, reliability, availability, or completeness by us.</p>
      </div>
    </div>
    
    <p><Link to="/" className="back-link">Back to Home</Link></p>
  </div>
);

export default Legal;